import { FormEventHandler, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/logo.png";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const { resetToken } = useParams();

  const resetPassword: FormEventHandler = async (e) => {
    e.preventDefault();

    if (password.length < 8) {
      setPasswordWeak(true);
      return;
    } else {
      setPasswordWeak(false);
    }

    if (password !== confirmPassword) {
      setPassword("");
      setConfirmPassword("");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    try {
      setLoading(true);

      await axios.put(
        `https://api.firstwebster.com/api/auth/passwordreset/${resetToken}`,
        { password }
      );

      setLoading(false);
      toast.success("Password reset successfully!");
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <div className="new-password ptb-150">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-5">
              <div className="new-password-area">
                <div className="account-wrapper">
                  <span className="account-cross-btn" />
                  <div className="account-logo text-center">
                    <Link to="/" className="site-logo">
                      <img src={logo} alt="logo" />
                    </Link>
                  </div>
                  <form
                    className="account-form ptb-30"
                    onSubmit={resetPassword}
                  >
                    <div className="row ml-b-20">
                      <label htmlFor="new-password">Enter New Password</label>
                      <div className="col-lg-12 form-group show_hide_password">
                        <input
                          type="password"
                          name="password"
                          id="new-password"
                          className="form-control form--control"
                          placeholder="New Password"
                          required
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordWeak(false);
                            setPasswordError(false);
                          }}
                        />
                        {passwordWeak && (
                          <p
                            className="text-danger"
                            style={{ fontSize: "15px", marginTop: "8px" }}
                          >
                            Password should be at least 8 characters long
                          </p>
                        )}
                      </div>
                      <label htmlFor="confirm-password">Confirm Password</label>
                      <div className="col-lg-12 form-group show_hide_password-2">
                        <input
                          type="password"
                          name="password"
                          id="confirm-password"
                          className="form-control form--control"
                          placeholder="Confirm Password"
                          required
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setPasswordWeak(false);
                            setPasswordError(false);
                          }}
                        />
                        {passwordError && (
                          <p
                            className="text-danger"
                            style={{ fontSize: "15px", marginTop: "8px" }}
                          >
                            Passwords do not match
                          </p>
                        )}
                      </div>
                      <div className="col-lg-12 form-group text-center pt-3">
                        {loading ? (
                          <button
                            type="button"
                            className="btn--base w-100"
                            disabled
                          >
                            Confirming...
                          </button>
                        ) : (
                          <button type="submit" className="btn--base w-100">
                            Confirm
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        hideProgressBar
        theme="colored"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default ResetPassword;
