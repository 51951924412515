import { useState, FormEventHandler } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../store/redux/userRedux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/logo.png";
import Preloader from "./Preloader";

const Header = (props: any) => {
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [input, setInput] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [modalActive, setModalActive] = useState("login");

  const [passwordWeak, setPasswordWeak] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [registerFormData, setRegisterFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
  });

  const user = useSelector((state: any) => state.user.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function getRandom(length: number) {
    return Math.floor(
      Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
    );
  }

  const inputChange = (e: any) => {
    setRegisterFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const openLoginModal = () => {
    setLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setLoginModalOpen(false);
  };

  const loginHandler: FormEventHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      if (type === "email") {
        const { data } = await axios.post(
          "https://api.firstwebster.com/api/auth/login",
          {
            email: input?.toLowerCase(),
            password,
          }
        );

        setLoading(false);
        dispatch(loginUser(data));
        localStorage.setItem("firstwebster", data.token);
        navigate("/dashboard");
      } else {
        const { data } = await axios.post(
          "https://api.firstwebster.com/api/auth/login",
          {
            accountNumber: input,
            password,
          }
        );

        setLoading(false);
        dispatch(loginUser(data));
        localStorage.setItem("firstwebster", data.token);
        navigate("/dashboard");
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        if (error.response.data.error === "Account disabled") {
          toast.error("Account disabled. Please contact support.");
        } else {
          toast.error(error.response.data.error);
        }
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const registerHandler: FormEventHandler = async (e) => {
    e.preventDefault();

    if (registerFormData.password.length < 8) {
      setPasswordWeak(true);
      return;
    } else {
      setPasswordWeak(false);
    }

    if (registerFormData.password !== registerFormData.confirmPassword) {
      setRegisterFormData((prevState) => ({
        ...prevState,
        password: "",
        confirmPassword: "",
      }));
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    try {
      setLoading(true);

      const { data } = await axios.post(
        "https://api.firstwebster.com/api/auth/register",
        {
          firstName: registerFormData.firstName,
          middleName: registerFormData.middleName,
          lastName: registerFormData.lastName,
          email: registerFormData.email?.toLowerCase(),
          password: registerFormData.password,
          phone: registerFormData.phone,
          accountNumber: `9${getRandom(11)}`,
        }
      );

      if (data.message === "Verification email sent") {
        setLoading(false);
        navigate("/otp-verification");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      {loading && <Preloader />}
      <nav className="navber-section header-section">
        <div className="header">
          <div className="header-bottom-area">
            <div
              className="container custom-container"
              style={{ paddingTop: "16px", paddingBottom: "16px" }}
            >
              <div className="header-menu-content">
                <nav className="navbar navbar-expand-lg p-0">
                  <Link className="site-logo site-title" to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                  <button
                    className="navbar-toggler ms-auto"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="fas fa-bars" />
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                    style={{ paddingBottom: "12px" }}
                  >
                    <ul className="navbar-nav main-menu ms-auto">
                      <li>
                        <Link
                          to="/"
                          className={`${
                            props.activePage === "home" ? "active" : ""
                          }`}
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/about"
                          className={`${
                            props.activePage === "about" ? "active" : ""
                          }`}
                        >
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services"
                          className={`${
                            props.activePage === "services" ? "active" : ""
                          }`}
                        >
                          Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/contact"
                          className={`${
                            props.activePage === "contact" ? "active" : ""
                          }`}
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                    <div className="header-action">
                      {user ? (
                        <Link
                          to="/dashboard"
                          className="btn--base header-account-btn"
                        >
                          Dashboard
                        </Link>
                      ) : (
                        <button
                          className="btn--base header-account-btn"
                          onClick={openLoginModal}
                        >
                          Login Now
                        </button>
                      )}
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {loginModalOpen && (
        <section className="account active">
          <div className="account-area">
            <div
              className={`${
                modalActive === "login"
                  ? "account-wrapper change-form"
                  : "account-wrapper"
              }`}
            >
              <span className="account-cross-btn" onClick={closeLoginModal} />
              <div className="account-logo text-center">
                <Link to="/" className="site-logo">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <h5 className="title">Login Information</h5>

              <form className="account-form" onSubmit={loginHandler}>
                <div className="row ml-b-20">
                  <div className="col-lg-12 form-group">
                    <input
                      type="text"
                      required
                      className="form-control form--control"
                      name="text"
                      placeholder="Email or Account number"
                      spellCheck="false"
                      data-ms-editor="true"
                      value={input}
                      onChange={(e) => {
                        setInput(e.target.value);
                        const value = e.target.value;
                        if (!isNaN(+value)) {
                          setType("accountNumber");
                        } else {
                          setType("email");
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-12 form-group show_hide_password">
                    <input
                      type="password"
                      name="password"
                      className="form-control form--control"
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-12 form-group">
                    <div className="forgot-item">
                      <label>
                        <Link to="/forgot-password">Forgot Password?</Link>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-12 form-group text-center">
                    {loading ? (
                      <button
                        className="btn--base w-100"
                        type="button"
                        disabled
                      >
                        Login Now
                      </button>
                    ) : (
                      <button type="submit" className="btn--base w-100">
                        Login Now
                      </button>
                    )}
                  </div>

                  {/* <div className="col-lg-12 text-center">
                    <div className="account-item">
                      <label>
                        Don't Have An Account?{" "}
                        <a
                          href="#/"
                          className="account-control-btn"
                          onClick={() => setModalActive("register")}
                        >
                          Register Now
                        </a>
                      </label>
                    </div>
                  </div> */}
                </div>
              </form>
            </div>
            <div
              className={`${
                modalActive === "register"
                  ? "account-wrapper change-form"
                  : "account-wrapper"
              }`}
            >
              <span className="account-cross-btn" onClick={closeLoginModal} />
              <div className="account-logo text-center">
                <Link className="site-logo" to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <h5 className="title">Register Information</h5>

              <form className="account-form" onSubmit={registerHandler}>
                <div className="row ml-b-20">
                  <div className="col-lg-12 form-group">
                    <input
                      type="text"
                      className="form-control form--control"
                      name="firstName"
                      placeholder="First Name *"
                      spellCheck="false"
                      data-ms-editor="true"
                      required
                      value={registerFormData.firstName}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  <div className="col-lg-12 form-group">
                    <input
                      type="text"
                      className="form-control form--control"
                      name="middleName"
                      placeholder="Middle Name"
                      spellCheck="false"
                      data-ms-editor="true"
                      value={registerFormData.middleName}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  <div className="col-lg-12 form-group">
                    <input
                      type="text"
                      className="form-control form--control"
                      name="lastName"
                      placeholder="Last Name *"
                      spellCheck="false"
                      data-ms-editor="true"
                      required
                      value={registerFormData.lastName}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  <div className="col-lg-12 form-group">
                    <input
                      type="email"
                      className="form-control form--control"
                      name="email"
                      placeholder="Email address *"
                      required
                      value={registerFormData.email}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  <div className="col-lg-12 form-group">
                    <input
                      type="tel"
                      className="form-control form--control"
                      name="phone"
                      placeholder="Phone Number *"
                      required
                      value={registerFormData.phone}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  <div className="col-lg-12 form-group show_hide_password">
                    <input
                      type="password"
                      name="password"
                      className="form-control form--control"
                      placeholder="Password *"
                      required
                      value={registerFormData.password}
                      onChange={(e) => {
                        inputChange(e);
                        setPasswordError(false);
                        setPasswordWeak(false);
                      }}
                    />
                    {passwordWeak && (
                      <p
                        className="text-danger"
                        style={{ fontSize: "15px", marginTop: "8px" }}
                      >
                        Password should be at least 8 characters long
                      </p>
                    )}
                  </div>
                  <div className="col-lg-12 form-group show_hide_password">
                    <input
                      type="password"
                      name="confirmPassword"
                      className="form-control form--control"
                      placeholder="Confirm Password *"
                      required
                      value={registerFormData.confirmPassword}
                      onChange={(e) => {
                        inputChange(e);
                        setPasswordError(false);
                        setPasswordWeak(false);
                      }}
                    />
                    {passwordError && (
                      <p
                        className="text-danger"
                        style={{ fontSize: "15px", marginTop: "8px" }}
                      >
                        Passwords do not match
                      </p>
                    )}
                  </div>
                  <div className="col-lg-12 form-group text-center">
                    {loading ? (
                      <button
                        type="button"
                        className="btn--base w-100"
                        disabled
                      >
                        Registering...
                      </button>
                    ) : (
                      <button type="submit" className="btn--base w-100">
                        Register Now
                      </button>
                    )}
                  </div>
                  <div className="col-lg-12 text-center">
                    <div className="account-item">
                      <label>
                        Already Have An Account?{" "}
                        <a
                          href="#/"
                          className="account-control-btn"
                          onClick={() => setModalActive("login")}
                        >
                          Login Now
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      )}

      {loginModalOpen && (
        <div className="body-overlay active" onClick={closeLoginModal} />
      )}

      <ToastContainer
        hideProgressBar
        theme="colored"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default Header;
