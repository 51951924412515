import logo from "../assets/icon.png";

const Preloader = () => {
  return (
    <div
      style={{
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        zIndex: "9999",
        width: "100%",
        height: "100%",
      }}
    >
      <div>
        <img
          src={logo}
          alt="loading"
          className="preloader"
          style={{ height: "100px" }}
        />
      </div>
    </div>
  );
};

export default Preloader;
