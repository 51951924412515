import BackToTop from "../components/BackToTop";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Services = () => {
  return (
    <>
      <BackToTop />
      <Header activePage="services" />
      <section
        className="service-baner bg_img"
        data-background="./assets/images/baner/service-baner.webp"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="baner-left">
                <h1 className="title">Services</h1>
                <p>
                  Home / <span className="text--base">Services</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="loan-service ptb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="left-side">
                <img src="/assets/images/blog/loan-service.webp" alt="img" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="right-side">
                <h2 className="title pb-3">OVERVIEW</h2>
                <p>
                  Corporate Banking Division of First Webster offers
                  comprehensive suite of products &amp; services to cater for
                  the growing needs of corporate customers from various business
                  segments.
                </p>
                <div className="content-title">
                  <span className="text--base">
                    At First Webster we have following leverage to provide you
                    best corporate banking experience:
                  </span>
                </div>
                <ul className="exprence">
                  <li>
                    A team of expert and dedicated relationship managers to
                    identify your business requirement and provide you solutions
                    through diversified products and services
                  </li>
                  <li>
                    Wide network throughout the country to identify remote most
                    corporate customers and provide them with tailor made
                    banking solutions
                  </li>
                  <li>
                    Our Cash Management wing offers comprehensive portfolio of
                    cash management services under one roof to enhance your
                    business performance
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bank-working ptb-80">
        <img
          src="/assets/images/element/footer-shape.webp"
          alt="element"
          className="bank-element"
        />
        <div className="container">
          <div className="working-area">
            <h2 className="title">How Online Banking Works</h2>
            <p>
              Online banking offers convenience and flexibility, allowing you to
              manage your finances efficiently and securely from anywhere with
              an internet connection. It's a valuable tool for modern banking
              that puts you in control of your money.
            </p>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="working-item d-flex">
                  <div className="item-icon">
                    <i className="las la-check" />
                  </div>
                  <div className="item-name">
                    <p>Opening accounts</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="working-item d-flex">
                  <div className="item-icon">
                    <i className="las la-check" />
                  </div>
                  <div className="item-name">
                    <p>Applying for loans</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="working-item d-flex">
                  <div className="item-icon">
                    <i className="las la-check" />
                  </div>
                  <div className="item-name">
                    <p>Staying informed</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="working-item d-flex">
                  <div className="item-icon">
                    <i className="las la-check" />
                  </div>
                  <div className="item-name">
                    <p>Transferring funds</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="working-item d-flex">
                  <div className="item-icon">
                    <i className="las la-check" />
                  </div>
                  <div className="item-name">
                    <p>Depositing checks</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="working-item d-flex">
                  <div className="item-icon">
                    <i className="las la-check" />
                  </div>
                  <div className="item-name">
                    <p>24/7 on time services</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="process-section ptb-60">
        <div className="container">
          <div className="section-title pb-40 text-center">
            <h2 className="title">Let's Start Now</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col col-sm-6">
              <div className="account-area">
                <div className="account-thumb d-flex">
                  <div className="account-tipe">
                    <img
                      src="/assets/images/element/create-profile.webp"
                      alt="element"
                    />
                  </div>
                  <div className="account-title">
                    <h3 className="title">Create An Account</h3>
                  </div>
                </div>
                <p>
                  Your new account opens the door to a world of possibilities.
                  You can start exploring our services, making transactions, and
                  enjoying all the benefits we have to offer.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col col-sm-6">
              <div className="account-area">
                <div className="account-thumb d-flex">
                  <div className="account-tipe">
                    <img
                      src="/assets/images/element/attach-bank.webp"
                      alt="element"
                    />
                  </div>
                  <div className="account-title">
                    <h3 className="title">Attach bank Account</h3>
                  </div>
                </div>
                <p>
                  Attaching your bank account streamlines your financial
                  interactions with us, making it easier to manage your funds
                  and access our services. It's a secure and convenient way to
                  get the most out of our platform.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col col-sm-6">
              <div className="account-area">
                <div className="account-thumb d-flex">
                  <div className="account-tipe">
                    <img
                      src="/assets/images/element/money-hand.webp"
                      alt="element"
                    />
                  </div>
                  <div className="account-title">
                    <h3 className="title">Send Money</h3>
                  </div>
                </div>
                <p>
                  Sending money has never been this easy. Whether it's splitting
                  bills with friends or helping family, our platform simplifies
                  the process, making it convenient for you to send and receive
                  funds securely.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Services;
