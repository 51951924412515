import { useState, useEffect, FormEventHandler } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../store/redux/userRedux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import avatar from "../../assets/avatar.webp";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../../firebase";
import BackToTop from "../../components/BackToTop";
import HeaderRight from "../../components/HeaderRight";
import Sidebar from "../../components/Sidebar";
import Preloader from "../../components/Preloader";

const Profile = () => {
  // const [oldPassword, setOldPassword] = useState("");
  // const [newPassword, setNewPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  // const [passwordError, setPasswordError] = useState(false);
  // const [passwordWeak, setPasswordWeak] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<any>();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [occupation, setOccupation] = useState("");
  const [pageLoading, setPageLoading] = useState(true);

  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("firstwebster");

    const getUser = async () => {
      try {
        const { data } = await axios.get(
          "https://api.firstwebster.com/api/user",
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        setFirstName(data.firstName);
        setMiddleName(data.middleName);
        setLastName(data.lastName);
        setEmail(data.email);
        setPhone(data.phone);
        setAddress(data.address);
        setOccupation(data.occupation);
        setTimeout(() => {
          setPageLoading(false);
        }, 300);
      } catch (error: any) {
        if (error.response) {
          if (error.response.data === "Token is not valid!") {
            localStorage.removeItem("firstwebster");
            dispatch(logoutUser());
          } else {
            localStorage.removeItem("firstwebster");
            dispatch(logoutUser());
          }
        }
      }
    };

    getUser();
  }, [dispatch]);

  // const changePassword: FormEventHandler = async (e) => {
  //   e.preventDefault();

  //   const token = localStorage.getItem("firstwebster");

  //   if (newPassword.length < 8) {
  //     setPasswordWeak(true);
  //     return;
  //   } else {
  //     setPasswordWeak(false);
  //   }

  //   if (newPassword !== confirmPassword) {
  //     setNewPassword("");
  //     setConfirmPassword("");
  //     setPasswordError(true);
  //     return;
  //   } else {
  //     setPasswordError(false);
  //   }

  //   try {
  //     setLoading(true);

  //     await axios.put(
  //       `https://api.firstwebster.com/api/auth/changepassword/${user?._id}`,
  //       { oldPassword, newPassword },
  //       {
  //         headers: { token: `Bearer ${token}` },
  //       }
  //     );

  //     setLoading(false);
  //     toast.success("Password changed!");
  //     setTimeout(() => {
  //       navigate(0);
  //     }, 1800);
  //   } catch (error: any) {
  //     setLoading(false);
  //     if (error.response) {
  //       toast.error(error.response.data.error);
  //     } else {
  //       toast.error("Something went wrong");
  //     }
  //   }
  // };

  const editUser: FormEventHandler = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("firstwebster");

    if (image) {
      setLoading(true);

      const fileName = new Date().getTime() + image.name;
      const storage = getStorage(app);
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          setLoading(false);
          toast.error("Something went wrong");
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const edit = async () => {
              try {
                await axios.put(
                  `https://api.firstwebster.com/api/user/${user?._id}`,
                  {
                    firstName,
                    middleName,
                    lastName,
                    email,
                    phone,
                    occupation,
                    address,
                    picture: downloadURL,
                  },
                  {
                    headers: { token: `Bearer ${token}` },
                  }
                );

                setLoading(false);
                toast.success("Changes saved");
                setTimeout(() => {
                  navigate(0);
                }, 1800);
              } catch (error) {
                setLoading(false);
                toast.error("Something went wrong");
              }
            };

            edit();
          });
        }
      );
    } else {
      try {
        setLoading(true);

        await axios.put(
          `https://api.firstwebster.com/api/user/${user?._id}`,
          {
            firstName,
            middleName,
            lastName,
            email,
            phone,
            occupation,
            address,
          },
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        setLoading(false);
        toast.success("Changes saved");
        setTimeout(() => {
          navigate(0);
        }, 1800);
      } catch (error) {
        setLoading(false);
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      {pageLoading && <Preloader />}
      <BackToTop />
      <div className="page-wrapper bg-overlay-base">
        <Sidebar activePage="My Profile" />
        <div className="main-wrapper">
          <div className="main-body-wrapper">
            <nav className="navbar-wrapper">
              <div className="dashboard-title-part">
                <div className="left">
                  <div className="icon">
                    <button className="sidebar-menu-bar">
                      <i className="fas fa-exchange-alt" />
                    </button>
                  </div>
                  <div className="dashboard-path">
                    <span className="main-path" style={{ color: "#0d6efd" }}>
                      <Link to="/dashboard">Dashboard</Link>
                    </span>
                    <i className="las la-angle-right" />
                    <span className="active-path">My Profile</span>
                  </div>
                </div>
                <HeaderRight />
              </div>
            </nav>
            <div className="body-wrapper">
              <div className="row mb-20-none">
                <div className="col-xl-6 col-lg-6 mb-20">
                  <div className="custom-card mt-10">
                    <div className="dashboard-header-wrapper">
                      <h4 className="title">Profile Settings</h4>
                    </div>
                    <div className="card-body profile-body-wrapper">
                      <form className="card-form" onSubmit={editUser}>
                        <div className="profile-settings-wrapper">
                          <div className="preview-thumb profile-wallpaper">
                            <div className="avatar-preview">
                              <div
                                className="profilePicPreview bg_img"
                                data-background="/assets/images/baner/profile.webp"
                              />
                            </div>
                          </div>
                          <div className="profile-thumb-content">
                            <div className="preview-thumb profile-thumb">
                              {fileUploaded ? (
                                <img
                                  src={URL.createObjectURL(image)}
                                  className="avatar-preview"
                                  alt="user"
                                />
                              ) : (
                                <div className="avatar-preview">
                                  {user?.picture ? (
                                    <div
                                      className="profilePicPreview bg_img"
                                      style={{
                                        backgroundImage: `url(${user?.picture})`,
                                      }}
                                    />
                                  ) : (
                                    <div
                                      className="profilePicPreview bg_img"
                                      style={{
                                        backgroundImage: `url(${avatar})`,
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                              <div className="avatar-edit">
                                <input
                                  type="file"
                                  className="profilePicUpload"
                                  name="image"
                                  id="profilePicUpload2"
                                  accept="image/*"
                                  onChange={(e: any) => {
                                    setFileUploaded(true);
                                    setImage(e.target.files[0]);
                                    setChangesMade(true);
                                  }}
                                />
                                <label htmlFor="profilePicUpload2">
                                  <i className="las la-upload" />
                                </label>
                              </div>
                            </div>
                            <div className="profile-content">
                              <h6 className="username">
                                {user.firstName} {user?.middleName}{" "}
                                {user.lastName}
                              </h6>
                              <ul className="user-info-list mt-md-2">
                                <li>
                                  <i className="las la-envelope" />
                                  {user.email}
                                </li>
                              </ul>
                              <ul className="user-info-list mt-md-2">
                                <li>Account ID: {user.accountNumber}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="profile-form-area">
                          <div className="row">
                            <div className="col-xl-6 col-lg-6 form-group">
                              <label>
                                First Name <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="form--control"
                                placeholder="Antylina"
                                value={firstName}
                                required
                                onChange={(e) => {
                                  setFirstName(e.target.value);
                                  setChangesMade(true);
                                }}
                              />
                            </div>
                            <div className="col-xl-6 col-lg-6 form-group">
                              <label>Middle Name</label>
                              <input
                                type="text"
                                className="form--control"
                                value={middleName}
                                onChange={(e) => {
                                  setMiddleName(e.target.value);
                                  setChangesMade(true);
                                }}
                              />
                            </div>
                            <div className="col-xl-6 col-lg-6 form-group">
                              <label>
                                Last Name <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="form--control"
                                placeholder="Amelia"
                                value={lastName}
                                required
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                  setChangesMade(true);
                                }}
                              />
                            </div>
                            <div className="col-xl-6 col-lg-6 form-group">
                              <label>
                                Email <span>*</span>
                              </label>
                              <input
                                type="email"
                                className="form--control"
                                value={email}
                                required
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                  setChangesMade(true);
                                }}
                              />
                            </div>
                            <div className="col-xl-6 col-lg-6 form-group">
                              <label>
                                Phone <span>*</span>
                              </label>
                              <input
                                type="tel"
                                className="form--control"
                                value={phone}
                                required
                                onChange={(e) => {
                                  setPhone(e.target.value);
                                  setChangesMade(true);
                                }}
                              />
                            </div>
                            <div className="col-xl-6 col-lg-6 form-group">
                              <label>Occupation</label>
                              <input
                                type="text"
                                className="form--control"
                                value={occupation}
                                placeholder="Occupation"
                                onChange={(e) => {
                                  setOccupation(e.target.value);
                                  setChangesMade(true);
                                }}
                              />
                            </div>
                            <div className="col-xl-12 col-lg-12 form-group">
                              <label>Address</label>
                              <input
                                className="form--control"
                                type="text"
                                value={address}
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                  setChangesMade(true);
                                }}
                              />
                            </div>
                          </div>
                          {changesMade && (
                            <div className="col-xl-12 col-lg-12">
                              {loading ? (
                                <button
                                  type="button"
                                  className="btn--base w-100"
                                  disabled
                                >
                                  Updating...
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn--base w-100"
                                >
                                  Update
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-6 col-lg-6 mb-20">
                  <div className="custom-card mt-10">
                    <div className="dashboard-header-wrapper">
                      <h4 className="title">Change Password</h4>
                    </div>
                    <div className="card-body">
                      <form className="card-form" onSubmit={changePassword}>
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 form-group show_hide_password">
                            <label htmlFor="current-password">
                              Current Password<span>*</span>
                            </label>
                            <input
                              type="password"
                              id="current-password"
                              className="form--control"
                              placeholder="Enter current password"
                              value={oldPassword}
                              required
                              onChange={(e) => {
                                setOldPassword(e.target.value);
                                setPasswordError(false);
                                setPasswordWeak(false);
                              }}
                            />
                          </div>
                          <div className="col-xl-12 col-lg-12 form-group show_hide_password-2">
                            <label htmlFor="new-password">
                              New Password<span>*</span>
                            </label>
                            <input
                              type="password"
                              className="form--control"
                              id="new-password"
                              placeholder="Enter new password"
                              value={newPassword}
                              required
                              onChange={(e) => {
                                setNewPassword(e.target.value);
                                setPasswordError(false);
                                setPasswordWeak(false);
                              }}
                            />
                            {passwordWeak && (
                              <p
                                className="text-danger"
                                style={{ fontSize: "15px", marginTop: "8px" }}
                              >
                                Password should be at least 8 characters long
                              </p>
                            )}
                          </div>
                          <div className="col-xl-12 col-lg-12 form-group show_hide_password-3">
                            <label htmlFor="confirm-password">
                              Confirm Password<span>*</span>
                            </label>
                            <input
                              type="password"
                              className="form--control"
                              id="confirm-password"
                              placeholder="Confirm new password"
                              value={confirmPassword}
                              required
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                setPasswordError(false);
                                setPasswordWeak(false);
                              }}
                            />
                            {passwordError && (
                              <p
                                className="text-danger"
                                style={{ fontSize: "15px", marginTop: "8px" }}
                              >
                                Passwords do not match
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                          {loading ? (
                            <button
                              type="button"
                              className="btn--base w-100"
                              disabled
                            >
                              Changing...
                            </button>
                          ) : (
                            <button type="submit" className="btn--base w-100">
                              Change
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        hideProgressBar
        theme="colored"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default Profile;
