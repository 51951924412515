import { Link } from "react-router-dom";
import BackToTop from "../components/BackToTop";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Home = () => {
  return (
    <>
      <BackToTop />
      <Header activePage="home" />
      <section className="baner-section ptb-120">
        <div className="container">
          <div className="row align-items-center mb-30-none">
            <div className="col-lg-7 col-md-7 mb-30 mt-30">
              <div className="baner-content">
                <h1 className="title">
                  Secure &amp; Easy Online Banking Solution
                </h1>
                <p>
                  Experience hassle-free online payments with our secure and
                  user-friendly platform. We've got your transactions covered,
                  ensuring your peace of mind while you shop, pay bills, or
                  manage your finances online. Say goodbye to worries and hello
                  to convenience with our trusted online bank.
                </p>
                <div className="baner-btn">
                  <Link to="/about" className="btn--base">
                    Browse More{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="presenting-section ptb-60">
        <img
          src="/assets/images/element/footer-shape.webp"
          alt="element"
          className="presenting-element"
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <span className="sub-title pb-3 text--base">Our Features</span>
                <h2 className="title">
                  Presenting products and services that are right for you
                </h2>
              </div>
            </div>
          </div>
          <div className="presenting-area pt-60">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="presenting-thumb">
                  <div className="item-icon">
                    <i className="las la-university" />
                  </div>
                  <div className="thumb-title">
                    <h3 className="title">Online Business</h3>
                    <p>
                      In the digital age, online business is the key to reaching
                      a global audience, maximizing your profits, and realizing
                      your entrepreneurial dreams.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="presenting-thumb">
                  <div className="item-icon">
                    <i className="las la-comments-dollar" />
                  </div>
                  <div className="thumb-title">
                    <h3 className="title">Mobile Bank</h3>
                    <p>
                      Our banking system puts your financial world at your
                      fingertips. You can access your accounts, check balances,
                      transfer funds, and pay bills from anywhere, anytime.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="presenting-thumb">
                  <div className="item-icon">
                    <i className="las la-file-import" />
                  </div>
                  <div className="thumb-title">
                    <h3 className="title">Online Deposit</h3>
                    <p>
                      Our online deposit feature allows you to securely and
                      conveniently add funds to your accounts instantly without
                      leaving the comfort of your home.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="home-about ptb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-5">
              <div className="about-thumb">
                <img src="/assets/images/element/about-us.webp" alt="img" />
              </div>
            </div>
            <div className="col-lg-6 col-md-7">
              <div className="about-us">
                <span className="sub-title text--base pb-3">Why Choose Us</span>
                <h2 className="title">
                  Get World Class &amp; Fastest Online Payment Service
                </h2>
                <p>
                  Experience the pinnacle of online payment efficiency with our
                  world-class and lightning-fast service. Say goodbye to slow
                  transactions and hello to instant, secure payments that keep
                  pace with your needs. Join us today and unlock a new era of
                  online payment convenience.
                </p>
                <div className="choose-item d-flex">
                  <div className="item-icon">
                    <i className="las la-hand-point-right" />
                  </div>
                  <div className="content">
                    <h3 className="title">Low Costing</h3>
                    <p>
                      Our commitment to low costing means you get more value for
                      your money. Experience affordability like never before.
                    </p>
                  </div>
                </div>
                <div className="choose-item d-flex">
                  <div className="item-icon">
                    <i className="las la-hand-point-right" />
                  </div>
                  <div className="content">
                    <h3 className="title">Safe &amp; Secure</h3>
                    <p>
                      Our safe and secure solution ensures your data and
                      transactions are protected from threats.
                    </p>
                  </div>
                </div>
                <div className="choose-item d-flex">
                  <div className="item-icon">
                    <i className="las la-hand-point-right" />
                  </div>
                  <div className="content">
                    <h3 className="title">Live Support</h3>
                    <p>
                      Our dedicated team is here to help you, answering your
                      questions and resolving issues promptly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="statistics-section ptb-80">
        <img
          src="/assets/images/element/footer-shape.webp"
          alt="element"
          className="statistics-element"
        />
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="counter">
                <i className="las la-smile" />
                <div className="odo-area">
                  <h2
                    className="odo-title odometer"
                    data-odometer-final="70,543"
                  >
                    3M+
                  </h2>
                </div>
                <h4 className="title">Happy Customers</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="counter">
                <i className="las la-university" />
                <div className="odo-area">
                  <h2 className="odo-title odometer" data-odometer-final={345}>
                    19
                  </h2>
                </div>
                <h4 className="title">Years in Banking</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="counter">
                <i className="las la-share-alt" />
                <div className="odo-area">
                  <h2 className="odo-title odometer" data-odometer-final={142}>
                    90
                  </h2>
                </div>
                <h4 className="title">Our Branches</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="counter">
                <i className="las la-clipboard-check" />
                <div className="odo-area">
                  <h2
                    className="odo-title odometer"
                    data-odometer-final="1,1,2,232"
                  >
                    500k+
                  </h2>
                </div>
                <h4 className="title">Successful Projects</h4>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="bank-security ptb-80">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6">
              <div className="security-area">
                <span className="sub-title text--base pb-3">
                  Banking Security
                </span>
                <h2 className="title">
                  The Safest Way To Transact Your Money Fast
                </h2>
                <p>
                  Experience the fastest and safest way to handle your finances.
                  Our platform ensures swift, secure money transactions, giving
                  you peace of mind every time you transact.
                </p>
                <div className="online-securety d-flex align-items-center">
                  <div className="securety-icon">
                    <img
                      src="/assets/images/blog/Secure-Payment.webp"
                      alt="icon"
                    />
                  </div>
                  <div className="security-content">
                    <h3 className="title">Pay Online Securely</h3>
                    <p>
                      With our platform, you can pay online with confidence. We
                      prioritize your security, ensuring every transaction is
                      protected. Enjoy the ease of secure online payments,
                      worry-free.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="security-img text-center">
                <img src="/assets/images/blog/secutity-img.webp" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="service-section ptb-80">
        <img
          src="/assets/images/element/footer-shape.webp"
          alt="element"
          className="service-element"
        />
        <div className="container">
          <div className="section-title text-center">
            <span className="sub-title text--base pb-3">Our Services</span>
            <h2 className="title">Premium services for you</h2>
          </div>
          <div className="row pt-40">
            <div className="col-lg-6">
              <div className="service-item d-flex">
                <div className="service-icon">
                  <img
                    src="/assets/images/blog/Mobile-Banking.webp"
                    alt="img"
                  />
                </div>
                <div className="service-containt">
                  <h3 className="title">Mobile Banking</h3>
                  <p>
                    Access your accounts, check balances, transfer funds, and
                    pay bills from anywhere, anytime.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="service-item d-flex">
                <div className="service-icon">
                  <img
                    src="/assets/images/blog/Secure-Payment.webp"
                    alt="img"
                  />
                </div>
                <div className="service-containt">
                  <h3 className="title">Secure Payment</h3>
                  <p>
                    Our safe and secure solution ensures your data and
                    transactions are protected from threats.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="service-item d-flex">
                <div className="service-icon">
                  <img
                    src="/assets/images/blog/Saving-Account.webp"
                    alt="img"
                  />
                </div>
                <div className="service-containt">
                  <h3 className="title">Saving Account</h3>
                  <p>
                    Start saving today to build a secure tomorrow. Earn interest
                    on your deposits and watch your money grow.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="service-item d-flex">
                <div className="service-icon">
                  <img
                    src="/assets/images/blog/Personal-Seving.webp"
                    alt="img"
                  />
                </div>
                <div className="service-containt">
                  <h3 className="title">Personal Savings</h3>
                  <p>
                    Build a safety net for yourself with our easy-to-use savings
                    account. Start saving for a brighter future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
