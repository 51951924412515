import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../store/redux/userRedux";
import logo from "../assets/logo.png";

const Sidebar = (props: any) => {
  const [transferDropdownOpen, setTransferDropdownOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleTransferDropdown = () => {
    setTransferDropdownOpen(!transferDropdownOpen);
  };

  const logout = () => {
    localStorage.removeItem("firstwebster");
    dispatch(logoutUser());
    navigate("/");
  };

  return (
    <div className="sidebar">
      <div className="sidebar-inner">
        <div className="sidebar-inner-wrapper">
          <div className="sidebar-logo">
            <Link to="/dashboard" className="sidebar-main-logo">
              <img src={logo} alt="logo" />
            </Link>
            <button className="sidebar-menu-bar">
              <i className="fas fa-exchange-alt" />
            </button>
          </div>
          <div className="sidebar-menu-wrapper">
            <ul className="sidebar-menu">
              <li
                className={`${
                  props.activePage === "Dashboard"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }`}
              >
                <Link to="/dashboard">
                  <i className="menu-icon las la-palette" />
                  <span className="menu-title">Dashboard</span>
                </Link>
              </li>
              <li
                className={`${
                  transferDropdownOpen
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }`}
              >
                <a href="#/" onClick={toggleTransferDropdown}>
                  <i className="menu-icon las la-cloud-upload-alt" />
                  <span className="menu-title">Transfer Money</span>
                  <div className="sidebar-item-badge">
                    <span className="badge">
                      <i className="las la-angle-right" />
                    </span>
                  </div>
                </a>
                <ul
                  className="sidebar-submenu"
                  style={
                    transferDropdownOpen
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <li className="nav-item">
                    <Link to="/local-transfer">
                      <i className="las la-university" />
                      <span className="title">Local Transfer</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/international-transfer">
                      <i className="las la-share-square" />
                      <span className="title">International Transfer</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`${
                  props.activePage === "Transactions"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }`}
              >
                <Link to="/transactions">
                  <i className="menu-icon las la-recycle" />
                  <span className="menu-title">Transactions</span>
                </Link>
              </li>
              <li
                className={`${
                  props.activePage === "My Card"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }`}
              >
                <Link to="/my-card">
                  <i className="menu-icon las la-credit-card" />
                  <span className="menu-title">My Card</span>
                </Link>
              </li>
              <li
                className={`${
                  props.activePage === "My Profile"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }`}
              >
                <Link to="/profile">
                  <i className="menu-icon las la-user" />
                  <span className="menu-title">My Profile</span>
                </Link>
              </li>
              <li className="sidebar-menu-item">
                <a href="#/" onClick={logout}>
                  <i className="menu-icon las la-sign-out-alt" />
                  <span className="menu-title">Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="sidebar-doc-box bg_img"
          data-background="/assets/images/element/side-bg.webp"
        >
          <div className="sidebar-doc-content">
            <h4 className="title">Need Help?</h4>
            <p>Please contact our support.</p>
            <div className="sidebar-doc-btn">
              <Link to="/add-ticket" className="btn--base w-100">
                Get Support
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
