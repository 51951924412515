import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BackToTop from "../../components/BackToTop";
import HeaderRight from "../../components/HeaderRight";
import Sidebar from "../../components/Sidebar";
import Preloader from "../../components/Preloader";
import axios from "axios";

const Dashboard = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [transactionDetailsOpen, setTransactionDetailsOpen] = useState(false);
  const [detailsToShow, setDetailsToShow] = useState<any>({});

  const user = useSelector((state: any) => state.user.currentUser);

  const toggleDetails = () => {
    setTransactionDetailsOpen(!transactionDetailsOpen);
  };

  useEffect(() => {
    const token = localStorage.getItem("firstwebster");

    const getTransactions = async () => {
      const { data } = await axios.get(
        `https://api.firstwebster.com/api/transaction/${user?._id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactions(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getTransactions();
  }, [user?._id]);

  return (
    <>
      {pageLoading && <Preloader />}
      <BackToTop />
      <div className="page-wrapper bg-overlay-base">
        <Sidebar activePage="Dashboard" />
        <div className="main-wrapper">
          <div className="main-body-wrapper">
            <nav className="navbar-wrapper">
              <div className="dashboard-title-part">
                <div className="left">
                  <div className="icon">
                    <button className="sidebar-menu-bar">
                      <i className="fas fa-exchange-alt" />
                    </button>
                  </div>
                  <div className="dashboard-path">
                    <span className="main-path" style={{ color: "#0d6efd" }}>
                      <Link to="/dashboard">Dashboard</Link>
                    </span>
                    <i className="las la-angle-right" />
                    <span className="active-path">Dashboard</span>
                  </div>
                </div>
                <HeaderRight />
              </div>
            </nav>
            <div className="body-wrapper">
              <div className="dashboard-area mt-10">
                <div className="dashboard-header-wrapper">
                  <h3 className="title">
                    Welcome Back,{" "}
                    <span className="sub-title text--base">
                      {user.firstName}
                    </span>
                  </h3>
                </div>
                <div className="dashboard-item-area">
                  <div className="row mb-20-none">
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-20">
                      <div className="dashbord-item">
                        <div className="dashboard-content">
                          <h4 className="title">
                            Account Balance: ${user.balance?.toLocaleString()}
                            .00
                          </h4>
                          <span style={{ fontSize: "22px" }}>
                            Account No: {user.accountNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-20">
                      <div className="dashbord-item">
                        <div className="dashboard-content">
                          <h4 className="title">
                            Ledger Balance: ${user.balance?.toLocaleString()}
                            .00
                          </h4>
                          <span style={{ fontSize: "22px" }}>
                            Account No: {user.accountNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-list-area mt-60">
                <div className="dashboard-header-wrapper">
                  <h4 className="title">Latest Transactions</h4>
                  <div className="dashboard-btn-wrapper">
                    <div className="dashboard-btn">
                      <Link to="/transactions" className="btn--base">
                        View More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {transactions?.length > 0 ? (
                <>
                  {transactions?.slice(0, 3)?.map((transaction: any) => (
                    <div
                      className="dashboard-list-wrapper"
                      key={transaction._id}
                      onClick={() => {
                        setDetailsToShow(transaction);
                        toggleDetails();
                      }}
                    >
                      <div className="dashboard-list-item-wrapper show">
                        <div className="dashboard-list-item sent">
                          <div className="dashboard-list-left">
                            <div className="dashboard-list-user-wrapper">
                              <div className="dashboard-list-user-content">
                                {transaction.type === "Withdrawal" ? (
                                  <h4 className="title">Money Sent</h4>
                                ) : (
                                  <h4 className="title">Money Received</h4>
                                )}
                                <span className="sub-title">
                                  ${transaction.amount?.toLocaleString()}
                                  {transaction.status === "Unconfirmed" && (
                                    <span className="badge badge--warning ms-2">
                                      Unconfirmed
                                    </span>
                                  )}
                                  {transaction.status === "Pending" && (
                                    <span className="badge badge--warning ms-2">
                                      Pending
                                    </span>
                                  )}
                                  {transaction.status === "Cancelled" && (
                                    <span className="badge badge--danger ms-2">
                                      Cancelled
                                    </span>
                                  )}
                                  {transaction.status === "Completed" && (
                                    <span className="badge badge--success ms-2">
                                      Completed
                                    </span>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="dashboard-list-right">
                            <h4 className="main-money text--base">Details</h4>
                            <h5 className="exchange-money">
                              {new Date(
                                transaction?.dateTime
                              )?.toLocaleDateString()}
                            </h5>
                          </div>
                        </div>

                        {transaction._id === detailsToShow._id && (
                          <div
                            className="preview-list-wrapper"
                            style={
                              transactionDetailsOpen
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            <div className="preview-list-item">
                              <div className="preview-list-left">
                                <div className="preview-list-user-wrapper">
                                  <div className="preview-list-user-icon">
                                    <i className="las la-exchange-alt" />
                                  </div>
                                  <div className="preview-list-user-content">
                                    <span>Transaction ID</span>
                                  </div>
                                </div>
                              </div>
                              <div className="preview-list-right">
                                <span>{detailsToShow.transactionId}</span>
                              </div>
                            </div>
                            <div className="preview-list-item">
                              <div className="preview-list-left">
                                <div className="preview-list-user-wrapper">
                                  <div className="preview-list-user-icon">
                                    <i className="las la-exchange-alt" />
                                  </div>
                                  <div className="preview-list-user-content">
                                    <span>Transaction Type</span>
                                  </div>
                                </div>
                              </div>
                              <div className="preview-list-right">
                                <span>
                                  {detailsToShow.type === "Withdrawal"
                                    ? "Debit"
                                    : "Credit"}
                                </span>
                              </div>
                            </div>
                            <div className="preview-list-item">
                              <div className="preview-list-left">
                                <div className="preview-list-user-wrapper">
                                  <div className="preview-list-user-icon">
                                    <i className="las la-info-circle" />
                                  </div>
                                  <div className="preview-list-user-content">
                                    <span>Details</span>
                                  </div>
                                </div>
                              </div>
                              <div className="preview-list-right">
                                {transaction.type === "Withdrawal" ? (
                                  <span>Money Transfer</span>
                                ) : (
                                  <span>Deposit</span>
                                )}
                              </div>
                            </div>
                            {transaction.type === "Withdrawal" && (
                              <div className="preview-list-item">
                                <div className="preview-list-left">
                                  <div className="preview-list-user-wrapper">
                                    <div className="preview-list-user-icon">
                                      <i className="las la-info-circle" />
                                    </div>
                                    <div className="preview-list-user-content">
                                      <span>Transfer Mode</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="preview-list-right">
                                  {transaction.transferOption === "Local" ? (
                                    <span>Local</span>
                                  ) : (
                                    <span>International</span>
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="preview-list-item">
                              <div className="preview-list-left">
                                <div className="preview-list-user-wrapper">
                                  <div className="preview-list-user-icon">
                                    <i className="las la-wallet" />
                                  </div>
                                  <div className="preview-list-user-content">
                                    <span>Amount</span>
                                  </div>
                                </div>
                              </div>
                              <div className="preview-list-right">
                                <span>
                                  ${transaction.amount?.toLocaleString()}
                                </span>
                              </div>
                            </div>
                            <div className="preview-list-item">
                              <div className="preview-list-left">
                                <div className="preview-list-user-wrapper">
                                  <div className="preview-list-user-icon">
                                    <i className="lab la-artstation" />
                                  </div>
                                  <div className="preview-list-user-content">
                                    <span>Status</span>
                                  </div>
                                </div>
                              </div>
                              <div className="preview-list-right">
                                {transaction.status === "Unconfirmed" && (
                                  <span className="badge badge--warning">
                                    Unconfirmed
                                  </span>
                                )}
                                {transaction.status === "Pending" && (
                                  <span className="badge badge--warning">
                                    Pending
                                  </span>
                                )}
                                {transaction.status === "Cancelled" && (
                                  <span className="badge badge--danger">
                                    Cancelled
                                  </span>
                                )}
                                {transaction.status === "Completed" && (
                                  <span className="badge badge--success">
                                    Completed
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="preview-list-item">
                              <div className="preview-list-left">
                                <div className="preview-list-user-wrapper">
                                  <div className="preview-list-user-icon">
                                    <i className="las la-clock" />
                                  </div>
                                  <div className="preview-list-user-content">
                                    <span>Time</span>
                                  </div>
                                </div>
                              </div>
                              <div className="preview-list-right">
                                <span>
                                  {new Date(
                                    transaction?.dateTime
                                  )?.toLocaleTimeString()}
                                </span>
                              </div>
                            </div>
                            <div className="preview-list-item">
                              <div className="preview-list-left">
                                <div className="preview-list-user-wrapper">
                                  <div className="preview-list-user-icon">
                                    <i className="las la-calendar" />
                                  </div>
                                  <div className="preview-list-user-content">
                                    <span>Date</span>
                                  </div>
                                </div>
                              </div>
                              <div className="preview-list-right">
                                <span>
                                  {new Date(
                                    transaction?.dateTime
                                  )?.toDateString()}
                                </span>
                              </div>
                            </div>
                            <div className="preview-list-item">
                              <div className="preview-list-left">
                                <div className="preview-list-user-wrapper">
                                  <div className="preview-list-user-icon">
                                    <i className="las la-calendar" />
                                  </div>
                                  <div className="preview-list-user-content">
                                    <span>Account name</span>
                                  </div>
                                </div>
                              </div>
                              <div className="preview-list-right">
                                <span>
                                  {transaction.bankDetails?.accountName}
                                </span>
                              </div>
                            </div>
                            <div className="preview-list-item">
                              <div className="preview-list-left">
                                <div className="preview-list-user-wrapper">
                                  <div className="preview-list-user-icon">
                                    <i className="las la-calendar" />
                                  </div>
                                  <div className="preview-list-user-content">
                                    <span>Bank name</span>
                                  </div>
                                </div>
                              </div>
                              <div className="preview-list-right">
                                <span>{transaction.bankDetails?.bankName}</span>
                              </div>
                            </div>
                            <div className="preview-list-item">
                              <div className="preview-list-left">
                                <div className="preview-list-user-wrapper">
                                  <div className="preview-list-user-icon">
                                    <i className="las la-calendar" />
                                  </div>
                                  <div className="preview-list-user-content">
                                    <span>Account number</span>
                                  </div>
                                </div>
                              </div>
                              <div className="preview-list-right">
                                <span>
                                  {transaction.bankDetails?.accountNumber}
                                </span>
                              </div>
                            </div>
                            {transaction.bankDetails?.country && (
                              <div className="preview-list-item">
                                <div className="preview-list-left">
                                  <div className="preview-list-user-wrapper">
                                    <div className="preview-list-user-icon">
                                      <i className="las la-calendar" />
                                    </div>
                                    <div className="preview-list-user-content">
                                      <span>Country</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="preview-list-right">
                                  <span>
                                    {transaction.bankDetails?.country}
                                  </span>
                                </div>
                              </div>
                            )}
                            {transaction.bankDetails?.swiftCode && (
                              <div className="preview-list-item">
                                <div className="preview-list-left">
                                  <div className="preview-list-user-wrapper">
                                    <div className="preview-list-user-icon">
                                      <i className="las la-calendar" />
                                    </div>
                                    <div className="preview-list-user-content">
                                      <span>Swift Code</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="preview-list-right">
                                  <span>
                                    {transaction.bankDetails?.swiftCode}
                                  </span>
                                </div>
                              </div>
                            )}
                            {transaction.bankDetails?.routingNumber && (
                              <div className="preview-list-item">
                                <div className="preview-list-left">
                                  <div className="preview-list-user-wrapper">
                                    <div className="preview-list-user-icon">
                                      <i className="las la-calendar" />
                                    </div>
                                    <div className="preview-list-user-content">
                                      <span>Routing Number</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="preview-list-right">
                                  <span>
                                    {transaction.bankDetails?.routingNumber}
                                  </span>
                                </div>
                              </div>
                            )}
                            <div className="preview-list-item">
                              <div className="preview-list-left">
                                <div className="preview-list-user-wrapper">
                                  <div className="preview-list-user-icon">
                                    <i className="las la-calendar" />
                                  </div>
                                  <div className="preview-list-user-content">
                                    <span>Account Type</span>
                                  </div>
                                </div>
                              </div>
                              <div className="preview-list-right">
                                <span>
                                  {transaction.bankDetails?.accountType}
                                </span>
                              </div>
                            </div>
                            <div className="preview-list-item">
                              <div className="preview-list-left">
                                <div className="preview-list-user-wrapper">
                                  <div className="preview-list-user-icon">
                                    <i className="las la-calendar" />
                                  </div>
                                  <div className="preview-list-user-content">
                                    <span>Description</span>
                                  </div>
                                </div>
                              </div>
                              <div className="preview-list-right">
                                <span>{transaction.description}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <p style={{ textAlign: "center" }}>No transactions yet..</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
