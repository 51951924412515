import BackToTop from "../../components/BackToTop";
import Sidebar from "../../components/Sidebar";
import HeaderRight from "../../components/HeaderRight";
import { Link } from "react-router-dom";

const SupportTickets = () => {
  return (
    <>
      <BackToTop />
      <div className="page-wrapper bg-overlay-base">
        <Sidebar />
        <div className="main-wrapper">
          <div className="main-body-wrapper">
            <nav className="navbar-wrapper">
              <div className="dashboard-title-part">
                <div className="left">
                  <div className="icon">
                    <button className="sidebar-menu-bar">
                      <i className="fas fa-exchange-alt" />
                    </button>
                  </div>
                  <div className="dashboard-path">
                    <span className="main-path">
                      <Link to="/dashboard">Dashboard</Link>
                    </span>
                    <i className="las la-angle-right" />
                    <span className="active-path">Support Tickets</span>
                  </div>
                </div>
                <HeaderRight />
              </div>
            </nav>
            <div className="body-wrapper">
              <div className="table-area mt-10">
                <div className="table-wrapper">
                  <div className="dashboard-header-wrapper">
                    <h4 className="title">Support Tickets</h4>
                    <div className="dashboard-btn-wrapper">
                      <div className="dashboard-btn">
                        <Link to="/add-ticket" className="btn--base">
                          <i className="las la-plus me-1" />
                          Add New
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="custom-table">
                      <thead>
                        <tr>
                          <th>Ticket ID</th>
                          <th>Subject</th>
                          <th>Support Type</th>
                          <th>Category</th>
                          <th>User</th>
                          <th>Status</th>
                          <th>Last Reply</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>#ADT2022092201</td>
                          <td>
                            <span className="text--info">For 404 Error</span>
                          </td>
                          <td>Services</td>
                          <td>PHP Laravel</td>
                          <td>Sean Black</td>
                          <td>
                            <span className="badge badge--info">Active</span>
                          </td>
                          <td>2022-05-30 03:46 PM</td>
                          <td>
                            <a
                              href="support-chat.html"
                              className="btn btn--base"
                            >
                              <i className="las la-comment" />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>#ADT2022092202</td>
                          <td>
                            <span className="text--success">
                              For Page Not Found
                            </span>
                          </td>
                          <td>Sales</td>
                          <td>HTML Template</td>
                          <td>Merri Diamond</td>
                          <td>
                            <span className="badge badge--success">Solved</span>
                          </td>
                          <td>2022-04-21 02:22 PM</td>
                          <td>
                            <a
                              href="support-chat.html"
                              className="btn btn--base"
                            >
                              <i className="las la-comment" />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>#ADT2022092203</td>
                          <td>
                            <span className="text--warning">
                              For Authentication Problem
                            </span>
                          </td>
                          <td>Services</td>
                          <td>Flutter Full App</td>
                          <td>Sean Black</td>
                          <td>
                            <span className="badge badge--warning">
                              Pending
                            </span>
                          </td>
                          <td>2022-01-14 04:17 PM</td>
                          <td>
                            <a
                              href="support-chat.html"
                              className="btn btn--base"
                            >
                              <i className="las la-comment" />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <nav>
                  <ul className="pagination">
                    <li className="page-item disabled">
                      <span className="page-link" aria-hidden="true">
                        ‹
                      </span>
                    </li>
                    <li className="page-item active" aria-current="page">
                      <span className="page-link">1</span>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#/">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#/">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#/">
                        4
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        href="#/"
                        rel="next"
                        aria-label="Next »"
                      >
                        ›
                      </a>
                    </li>
                  </ul>
                </nav> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportTickets;
