import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import BackToTop from "../../components/BackToTop";
import HeaderRight from "../../components/HeaderRight";

const AddTicket = () => {
  return (
    <>
      <BackToTop />
      <div className="page-wrapper bg-overlay-base">
        <Sidebar />
        <div className="main-wrapper">
          <div className="main-body-wrapper">
            <nav className="navbar-wrapper">
              <div className="dashboard-title-part">
                <div className="left">
                  <div className="icon">
                    <button className="sidebar-menu-bar">
                      <i className="fas fa-exchange-alt" />
                    </button>
                  </div>
                  <div className="dashboard-path">
                    <span className="main-path" style={{ color: "#0d6efd" }}>
                      <Link to="/dashboard">Dashboard</Link>
                    </span>
                    <i className="las la-angle-right" />
                    <span className="active-path">Add support ticket</span>
                  </div>
                </div>
                <HeaderRight />
              </div>
            </nav>
            <div className="body-wrapper">
              <div className="row mb-20-none">
                <div className="col-xl-12 col-lg-12 mb-20">
                  <div className="custom-card mt-10">
                    <div className="dashboard-header-wrapper">
                      <h4 className="title">Add New Ticket</h4>
                    </div>
                    <div className="card-body">
                      <form className="card-form">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 form-group">
                            <label>
                              Name<span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form--control"
                              placeholder="Enter Name..."
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 form-group">
                            <label>
                              Email<span>*</span>
                            </label>
                            <input
                              type="email"
                              className="form--control"
                              placeholder="Enter Email..."
                            />
                          </div>
                          <div className="col-xl-12 col-lg-12 form-group">
                            <label>
                              Subject<span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form--control"
                              placeholder="Enter Subject..."
                            />
                          </div>
                          <div className="col-xl-12 col-lg-12 form-group">
                            <label>Message</label>
                            <textarea
                              className="form--control"
                              placeholder="Write Here…"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                          <button type="submit" className="btn--base w-100">
                            Add New
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTicket;
