import { FormEventHandler, useState } from "react";
import BackToTop from "../components/BackToTop";
import Footer from "../components/Footer";
import Header from "../components/Header";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendMessage: FormEventHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post("https://api.firstwebster.com/api/message", {
        name,
        email,
        message,
      });

      setLoading(false);
      toast.success("Message sent");
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <BackToTop />
      <Header activePage="contact" />
      <div className="contact-section pt-150 pb-40">
        <div className="container">
          <div className="row align-items-center mb-30-none">
            <div className="col-xl-7 col-lg-7 mb-30">
              <div className="contact-form-area">
                <div className="contact-header">
                  <span>Contact Us</span>
                  <h2 className="title">Feel Free To Get In Touch With Us</h2>
                </div>
                <form className="contact-form" onSubmit={sendMessage}>
                  <div className="row justify-content-center mb-10-none">
                    <div className="col-xl-6 col-lg-6 col-md-6 form-group">
                      <label>
                        Name<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="text"
                        className="form--control"
                        placeholder="Enter Name..."
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 form-group">
                      <label>
                        Email<span>*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form--control"
                        placeholder="Enter Email..."
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-xl-12 col-lg-12 form-group">
                      <label>
                        Message<span>*</span>
                      </label>
                      <textarea
                        className="form--control"
                        placeholder="Write Here..."
                        required
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-12 form-group">
                      {loading ? (
                        <button type="button" className="btn--base" disabled>
                          Sending Message...
                        </button>
                      ) : (
                        <button type="submit" className="btn--base">
                          Send Message
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 mb-30">
              <div className="contact-img">
                <img src="/assets/images/blog/contact.webp" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="location-section ptb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="location-img">
                <img
                  src="/assets/images/blog/contact-location.webp"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-lg-6 col-lg-6">
              <div className="location-content">
                <div className="contact-information">
                  <h3 className="title">Information</h3>
                  <p>Don't hesitaste to reach out to us</p>
                </div>
                <div className="contact-widget-box d-flex mt-3">
                  <div className="contact-icon">
                    <i className="las la-phone-volume" />
                  </div>
                  <div className="contact-containt">
                    <h4 className="title">Number</h4>
                    +1 (704) 981-1756
                  </div>
                </div>
                <div className="contact-widget-box d-flex mt-3">
                  <div className="contact-icon">
                    <i className="las la-map-marker" />
                  </div>
                  <div className="contact-containt">
                    <h4 className="title">Address</h4>
                    705 N Mountain Rd Newington, Connecticut(CT), 06111
                  </div>
                </div>
                <div className="contact-widget-box d-flex mt-3">
                  <div className="contact-icon">
                    <i className="las la-envelope" />
                  </div>
                  <div className="contact-containt">
                    <h4 className="title">Email</h4>
                    support@firstwebster.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer
        hideProgressBar
        theme="colored"
        autoClose={3000}
        closeButton={false}
      />

      <Footer />
    </>
  );
};

export default Contact;
