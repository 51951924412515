import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBKt1uLvTXXsKZEqNSDz-nPMM-WM5BKPFc",
  authDomain: "firstwebster-fd4da.firebaseapp.com",
  projectId: "firstwebster-fd4da",
  storageBucket: "firstwebster-fd4da.appspot.com",
  messagingSenderId: "360036182897",
  appId: "1:360036182897:web:a4a256ca79c4ad7976a4d0",
};

const app = initializeApp(firebaseConfig);

export default app;
