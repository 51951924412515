import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import avatar from "../assets/avatar.webp";

const HeaderRight = () => {
  const user = useSelector((state: any) => state.user.currentUser);

  return (
    <div className="right">
      <div className="header-user-wrapper">
        <div className="header-user-thumb">
          <Link to="/profile">
            {user?.picture ? (
              <img
                src={user.picture}
                alt="user"
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
            ) : (
              <img
                src={avatar}
                alt="user"
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderRight;
