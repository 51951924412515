import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../store/redux/userRedux";
import logo from "../assets/logo.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OtpVerification = () => {
  const [inputOne, setInputOne] = useState("");
  const [inputTwo, setInputTwo] = useState("");
  const [inputThree, setInputThree] = useState("");
  const [inputFour, setInputFour] = useState("");
  const [inputFive, setInputFive] = useState("");
  const [inputSix, setInputSix] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setCode(
      `${inputOne}${inputTwo}${inputThree}${inputFour}${inputFive}${inputSix}`
    );
  }, [inputOne, inputTwo, inputThree, inputFour, inputFive, inputSix]);

  let digitValidate = function (e: any) {
    e.target.value = e.target.value?.replace(/[^0-9]/g, "");
  };

  let tabChange = function (val: number) {
    let ele: any = document.querySelectorAll(".otp");
    if (ele[val - 1].value !== "" && val < 6) {
      ele[val].focus();
    } else if (ele[val - 1].value === "" && val > 1) {
      ele[val - 2].focus();
    }
  };

  const verify = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post(
        "https://api.firstwebster.com/api/auth/verify",
        {
          otp: +code,
        }
      );

      setLoading(false);
      toast.success("Account Verified!");
      setTimeout(() => {
        dispatch(loginUser(data));
        localStorage.setItem("firstwebster", data.token);
        navigate("/");
      }, 2000);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <section className="verification-otp ptb-150">
        <div className="container">
          <div className="row justify-content-center">
            <div className=" col-xl-6 col-lg-8 col-md-10 col-sm-12">
              <div className="verification-otp-area">
                <div className="account-wrapper otp-verification">
                  <div className="account-logo text-center">
                    <Link to="/" className="site-logo">
                      <img src={logo} alt="logo" />
                    </Link>
                  </div>
                  <div className="verification-otp-content ptb-30">
                    <h4 className="title text-center">Verify Account</h4>
                    <p className="d-block text-center">
                      We sent an otp to your email address. Please input the
                      code below
                    </p>
                  </div>
                  <form className="account-form">
                    <div className="row ml-b-20">
                      <div className="col-lg-12 form-group text-center">
                        <input
                          className="otp"
                          type="text"
                          onInput={(e: any) => digitValidate(e)}
                          onKeyUp={() => tabChange(1)}
                          maxLength={1}
                          required
                          value={inputOne}
                          onChange={(e) => setInputOne(e.target.value)}
                        />
                        <input
                          className="otp"
                          type="text"
                          onInput={(e: any) => digitValidate(e)}
                          onKeyUp={() => tabChange(2)}
                          maxLength={1}
                          required
                          value={inputTwo}
                          onChange={(e) => setInputTwo(e.target.value)}
                        />
                        <input
                          className="otp"
                          type="text"
                          onInput={(e: any) => digitValidate(e)}
                          onKeyUp={() => tabChange(3)}
                          maxLength={1}
                          required
                          value={inputThree}
                          onChange={(e) => setInputThree(e.target.value)}
                        />
                        <input
                          className="otp"
                          type="text"
                          onInput={(e: any) => digitValidate(e)}
                          onKeyUp={() => tabChange(4)}
                          maxLength={1}
                          required
                          value={inputFour}
                          onChange={(e) => setInputFour(e.target.value)}
                        />
                        <input
                          className="otp"
                          type="text"
                          onInput={(e: any) => digitValidate(e)}
                          onKeyUp={() => tabChange(5)}
                          maxLength={1}
                          required
                          value={inputFive}
                          onChange={(e) => setInputFive(e.target.value)}
                        />
                        <input
                          className="otp"
                          type="text"
                          onInput={(e: any) => digitValidate(e)}
                          onKeyUp={() => tabChange(6)}
                          maxLength={1}
                          required
                          value={inputSix}
                          onChange={(e) => setInputSix(e.target.value)}
                        />
                      </div>

                      {code.length === 6 && (
                        <div className="col-lg-12 form-group text-center">
                          {loading ? (
                            <button className="btn--base btn w-100" disabled>
                              Submitting...
                            </button>
                          ) : (
                            <button
                              className="btn--base btn w-100"
                              onClick={verify}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer
        hideProgressBar
        theme="colored"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default OtpVerification;
