import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadingUser,
  noUser,
  loadUser,
  logoutUser,
} from "./store/redux/userRedux";
import axios from "axios";
import Home from "./pages/Home";
import About from "./pages/About";
import AddTicket from "./pages/dashboard/AddTicket";
import LocalTransfer from "./pages/dashboard/LocalTransfer";
import CardDetails from "./pages/dashboard/CardDetails";
import Contact from "./pages/Contact";
import Dashboard from "./pages/dashboard/Dashboard";
import ForgotPassword from "./pages/ForgotPassword";
import MyCard from "./pages/dashboard/MyCard";
import ResetPassword from "./pages/ResetPassword";
import InternationalTransfer from "./pages/dashboard/InternationalTransfer";
import OtpVerification from "./pages/OtpVerification";
import Profile from "./pages/dashboard/Profile";
import Services from "./pages/Services";
import SupportTickets from "./pages/dashboard/SupportTickets";
import Transactions from "./pages/dashboard/Transactions";

const App = () => {
  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingUser());
    const token = localStorage.getItem("firstwebster");

    const getUser = async () => {
      try {
        const { data } = await axios.get(
          "https://api.firstwebster.com/api/user",
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        dispatch(loadUser(data));
      } catch (error: any) {
        if (error.response) {
          if (error.response.data === "Token is not valid!") {
            localStorage.removeItem("firstwebster");
            dispatch(logoutUser());
          }
        }
      }
    };

    if (token) {
      getUser();
    } else {
      dispatch(noUser());
    }
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route
        path="/add-ticket"
        element={user ? <AddTicket /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/local-transfer"
        element={user ? <LocalTransfer /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/card-details"
        element={user ? <CardDetails /> : <Navigate replace to={"/"} />}
      />
      <Route path="/contact" element={<Contact />} />
      <Route
        path="/dashboard"
        element={user ? <Dashboard /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/forgot-password"
        element={!user ? <ForgotPassword /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/my-card"
        element={user ? <MyCard /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/international-transfer"
        element={
          user ? <InternationalTransfer /> : <Navigate replace to={"/"} />
        }
      />
      <Route
        path="/otp-verification"
        element={!user ? <OtpVerification /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/profile"
        element={user ? <Profile /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/passwordreset/:resetToken"
        element={!user ? <ResetPassword /> : <Navigate replace to={"/"} />}
      />
      <Route path="/services" element={<Services />} />
      <Route
        path="/support-tickets"
        element={user ? <SupportTickets /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/transactions"
        element={user ? <Transactions /> : <Navigate replace to={"/"} />}
      />
    </Routes>
  );
};

export default App;
