import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

const Footer = () => {
  return (
    <footer className="footer-section pt-80 pb-30">
      <img
        src="/assets/images/element/footer-shape.webp"
        alt="element"
        className="footer-element"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="futter-logo">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div className="col-lg-6 mt-5">
            <div className="footer-top-area">
              <div className="footer-widget-wrapper ps-5">
                <div className="footer-widget">
                  <h4 className="widget-title">Company</h4>
                  <ul className="footer-list">
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-widget ps-3">
                  <h4 className="widget-title">Legal</h4>
                  <ul className="footer-list">
                    <li>
                      <a href="#/">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#/">Licensing</a>
                    </li>
                    <li>
                      <a href="#/">Terms of Use</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-widget ps-3">
                  <h4 className="widget-title">Quick Contact</h4>
                  <ul className="footer-list">
                    <li>705 N Mountain Rd Newington, Connecticut(CT), 06111</li>
                    <li>
                      <a href="#/">+1 (704) 981-1756</a>
                    </li>
                    <li>
                      <a href="mailto:support@firstwebster.com">
                        support@firstwebster.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-area">
          <div className="copyright-area">
            <p>Copyright {new Date().getFullYear()}. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
