const BackToTop = () => {
  return (
    <div
      id="jsScroll"
      className="scroll"
      onClick={() =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }
    >
      <i className="fa fa-angle-up"></i>
    </div>
  );
};

export default BackToTop;
