import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BackToTop from "../../components/BackToTop";
import HeaderRight from "../../components/HeaderRight";
import Sidebar from "../../components/Sidebar";
import icon from "../../assets/icon.png";

const CardDetails = () => {
  const user = useSelector((state: any) => state.user.currentUser);

  return (
    <>
      <BackToTop />
      <div className="page-wrapper bg-overlay-base">
        <Sidebar />
        <div className="main-wrapper">
          <div className="main-body-wrapper">
            <nav className="navbar-wrapper">
              <div className="dashboard-title-part">
                <div className="left">
                  <div className="icon">
                    <button className="sidebar-menu-bar">
                      <i className="fas fa-exchange-alt" />
                    </button>
                  </div>
                  <div className="dashboard-path">
                    <span className="main-path" style={{ color: "#0d6efd" }}>
                      <Link to="/dashboard">Dashboard</Link>
                    </span>
                    <i className="las la-angle-right" />
                    <span className="active-path">Card Details</span>
                  </div>
                </div>
                <HeaderRight />
              </div>
            </nav>
            <div className="body-wrapper ptb-40">
              <div className="row justify-content-center">
                <div className="col-xl-5 col-lg-5 col-md-12 text-center">
                  <div className="card-wrappe d-flex justify-content-center">
                    <div className="card-custom-area">
                      <div className="backgound">
                        <div className="left" />
                        <div className="right" />
                      </div>
                      <div className="card-custom">
                        <div className="flip">
                          <div
                            className="front bg_img"
                            data-background="/assets/images/element/card.webp"
                          >
                            <img src={icon} className="logo" alt="logo" />
                            <div className="investor">First Webster</div>
                            <div className="chip">
                              <div className="chip-line" />
                              <div className="chip-line" />
                              <div className="chip-line" />
                              <div className="chip-line" />
                              <div className="chip-main" />
                            </div>
                            <svg
                              className="wave"
                              viewBox="0 3.71 26.959 38.787"
                              width="26.959"
                              height="38.787"
                              fill="white"
                            >
                              <path d="M19.709 3.719c.266.043.5.187.656.406 4.125 5.207 6.594 11.781 6.594 18.938 0 7.156-2.469 13.73-6.594 18.937-.195.336-.57.531-.957.492a.9946.9946 0 0 1-.851-.66c-.129-.367-.035-.777.246-1.051 3.855-4.867 6.156-11.023 6.156-17.718 0-6.696-2.301-12.852-6.156-17.719-.262-.317-.301-.762-.102-1.121.204-.36.602-.559 1.008-.504z"></path>
                              <path d="M13.74 7.563c.231.039.442.164.594.343 3.508 4.059 5.625 9.371 5.625 15.157 0 5.785-2.113 11.097-5.625 15.156-.363.422-1 .472-1.422.109-.422-.363-.472-1-.109-1.422 3.211-3.711 5.156-8.551 5.156-13.843 0-5.293-1.949-10.133-5.156-13.844-.27-.309-.324-.75-.141-1.114.188-.367.578-.582.985-.542h.093z"></path>
                              <path d="M7.584 11.438c.227.031.438.144.594.312 2.953 2.863 4.781 6.875 4.781 11.313 0 4.433-1.828 8.449-4.781 11.312-.398.387-1.035.383-1.422-.016-.387-.398-.383-1.035.016-1.421 2.582-2.504 4.187-5.993 4.187-9.875 0-3.883-1.605-7.372-4.187-9.875-.321-.282-.426-.739-.266-1.133.164-.395.559-.641.984-.617h.094zM1.178 15.531c.121.02.238.063.344.125 2.633 1.414 4.437 4.215 4.437 7.407 0 3.195-1.797 5.996-4.437 7.406-.492.258-1.102.07-1.36-.422-.257-.492-.07-1.102.422-1.359 2.012-1.075 3.375-3.176 3.375-5.625 0-2.446-1.371-4.551-3.375-5.625-.441-.204-.676-.692-.551-1.165.122-.468.567-.785 1.051-.742h.094z"></path>
                            </svg>
                            <div className="card-number">
                              <div className="section">****</div>
                              <div className="section">****</div>
                              <div className="section">****</div>
                              <div className="section">0820</div>
                            </div>
                            <div className="end">
                              <span>exp. end:</span>
                              <span className="end-date">11/25</span>
                            </div>
                            <div className="card-holder">
                              {user.firstName} {user.lastName}
                            </div>
                            <div className="master">
                              <div className="circle master-red" />
                              <div className="circle master-yellow" />
                            </div>
                          </div>
                          <div className="back">
                            <div className="strip-black" />
                            <div className="ccv">
                              <label>ccv</label>
                              <div>456</div>
                            </div>
                            <div className="terms">
                              <p>
                                This card is property of First Webster. Misuse
                                is criminal offence. If found, please return to
                                First Webster or to the nearest bank with
                                MasterCard logo.
                              </p>
                              <p>
                                Use of this card is subject to the credit card
                                agreement.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-content d-flex text-center justify-content-center">
                    <div className="card-details">
                      <a
                        href="#/"
                        data-bs-toggle="modal"
                        data-bs-target="#fund-modal"
                      >
                        <div className="details-icon text--base">
                          <i className="las la-coins" />
                        </div>
                        <h5 className="title">Fund</h5>
                      </a>
                    </div>
                    <div className="card-details">
                      <Link to="/transactions">
                        <div className="details-icon text--base">
                          <i className="menu-icon las la-recycle" />
                        </div>
                        <h5 className="title">Transaction</h5>
                      </Link>
                    </div>
                    <div className="card-details">
                      <a
                        href="#/"
                        data-bs-toggle="modal"
                        data-bs-target="#Withdrow-modal"
                      >
                        <div className="details-icon text--base">
                          <i className="las la-hand-holding-usd" />
                        </div>
                        <h5 className="title">Withdraw</h5>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-78 col-lg-7 col-md-12">
                  <div className="card-prevew" style={{ color: "#fff" }}>
                    <div className="preview-list-wrapper">
                      <div className="preview-list-item">
                        <div className="preview-list-left">
                          <div className="preview-list-user-wrapper">
                            <div className="preview-list-user-icon">
                              <i className="las la-credit-card" />
                            </div>
                            <div className="preview-list-user-content">
                              <span>Card Type</span>
                            </div>
                          </div>
                        </div>
                        <div className="preview-list-right">
                          <span>Gold</span>
                        </div>
                      </div>
                      <div className="preview-list-item">
                        <div className="preview-list-left">
                          <div className="preview-list-user-wrapper">
                            <div className="preview-list-user-icon">
                              <i className="las la-exclamation-circle" />
                            </div>
                            <div className="preview-list-user-content">
                              <span>CVV</span>
                            </div>
                          </div>
                        </div>
                        <div className="preview-list-right">
                          <span>456</span>
                        </div>
                      </div>
                      <div className="preview-list-item">
                        <div className="preview-list-left">
                          <div className="preview-list-user-wrapper">
                            <div className="preview-list-user-icon">
                              <i className="las la-battery-half" />
                            </div>
                            <div className="preview-list-user-content">
                              <span>Expiration</span>
                            </div>
                          </div>
                        </div>
                        <div className="preview-list-right">
                          <span>2025-11</span>
                        </div>
                      </div>

                      <div className="preview-list-item">
                        <div className="preview-list-left">
                          <div className="preview-list-user-wrapper">
                            <div className="preview-list-user-icon">
                              <i className="las la-arrows-alt-h" />
                            </div>
                            <div className="preview-list-user-content">
                              <span>Freeze Card</span>
                            </div>
                          </div>
                        </div>
                        <div className="preview-list-right">
                          <div className="form-check form-switch toggle-fild-btn">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="fund-modal"
        tabIndex={-1}
        aria-labelledby="fundModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header" id="fundModal">
              <h4 className="modal-title">Fund</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="las la-times" />
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-4">
                <div className="col-12">
                  <div className="row">
                    <div className="form-group">
                      <label>
                        Enter Amount<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="text"
                        className="form--control"
                        placeholder="Enter Amount"
                      />
                      <div className="currency">
                        <p>USD</p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn--base w-100">
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="Withdrow-modal"
        tabIndex={-1}
        aria-labelledby="WithdrowModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header" id="WithdrowModal">
              <h4 className="modal-title">Withdraw</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="las la-times" />
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-4">
                <div className="col-12">
                  <div className="row">
                    <div className="form-group">
                      <label>
                        Withdraw Amount<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="text"
                        className="form--control"
                        placeholder="Enter Amount"
                      />
                      <div className="currency">
                        <p>USD</p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn--base w-100">
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDetails;
