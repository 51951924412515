import { useState, FormEventHandler } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const forgotPasswordHandler: FormEventHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const { data } = await axios.post(
        "https://api.firstwebster.com/api/auth/forgotpassword",
        {
          email: email.toLowerCase(),
        }
      );

      if (data === "Email sent") {
        setLoading(false);
        setEmail("");
        toast.success("Email sent!");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <section className="forgot-password ptb-150">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-5">
              <div className="forgot-password-area">
                <div className="account-wrapper">
                  <div className="account-logo text-center">
                    <Link to="/" className="site-logo">
                      <img src={logo} alt="logo" />
                    </Link>
                  </div>
                  <div className="forgot-password-content ptb-30">
                    <h3 className="title">Forgot Password?</h3>
                    <p>
                      Enter your email and we'll send you a link to reset your
                      password.
                    </p>
                  </div>
                  <form
                    className="account-form"
                    onSubmit={forgotPasswordHandler}
                  >
                    <div className="row ml-b-20">
                      <div className="col-lg-12 form-group">
                        <input
                          type="email"
                          required
                          className="form-control form--control"
                          name="text"
                          placeholder="Email address"
                          spellCheck="false"
                          data-ms-editor="true"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-12 form-group text-center">
                        {loading ? (
                          <button
                            className="btn--base btn w-100"
                            type="button"
                            disabled
                          >
                            Sending...
                          </button>
                        ) : (
                          <button className="btn--base btn w-100" type="submit">
                            Send OTP
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer
        hideProgressBar
        theme="colored"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default ForgotPassword;
