import BackToTop from "../components/BackToTop";
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {
  return (
    <>
      <BackToTop />
      <Header activePage="about" />
      <section className="about-baner ptb-60">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="baner-left">
                <h1 className="title">About Us</h1>
                <p>
                  Home / <span>About Us</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="loan-section ptb-80">
        <img
          src="/assets/images/element/footer-shape.webp"
          alt="element"
          className="loan-element"
        />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-6">
              <div className="business-loan">
                <div className="item">
                  <div className="loan-offer d-flex justify-content-between">
                    <div className="loan-icon">
                      <i className="las la-business-time" />
                    </div>
                  </div>
                  <div className="loan-content pt-4">
                    <h4 className="title">Get Local Receiving Accounts</h4>
                    <p>
                      Get paid in USD, EUR, GBP and more to receiving accounts
                      in North America, Europe, Australia and Asia, all without
                      opening local bank accounts
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="loan-item">
                <div className="Personal-Loan">
                  <div className="item">
                    <div className="loan-offer d-flex justify-content-between">
                      <div className="loan-icon">
                        <i className="las la-hand-holding-usd" />
                      </div>
                    </div>
                    <div className="loan-content pt-4">
                      <h4 className="title">Easily Access Your Funds</h4>
                      <p>
                        Withdraw your funds to your local bank account, pay
                        suppliers to their bank account.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Education Loan">
                <div className="item">
                  <div className="loan-offer d-flex justify-content-between">
                    <div className="loan-icon">
                      <i className="las la-school" />
                    </div>
                  </div>
                  <div className="loan-content pt-4">
                    <h4 className="title">Save on Fees</h4>
                    <p>
                      Enjoy a low 1% fee for USD transfers and zero fees for
                      other currencies. Payments to selected accounts are free
                      of charge.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="serviceArea">
                <h2 className="title">We provide awesome services here</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="smart-banking ptb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="smart-banking-img">
                <img src="/assets/images/blog/smart-bank.webp" alt="alt" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="smart-banking-content">
                <span className="sub-title text--base pb-3">Smart Banking</span>
                <h2 className="title">The Better Way To Transact Online</h2>

                <div className="smart-banking-step d-flex">
                  <div className="step-icon">
                    <i className="las la-check" />
                  </div>
                  <div className="step-content">
                    <p>Cards that work all across the world.</p>
                  </div>
                </div>
                <div className="smart-banking-step d-flex">
                  <div className="step-icon">
                    <i className="las la-check" />
                  </div>
                  <div className="step-content">
                    <p>Highest Returns on your investments.</p>
                  </div>
                </div>
                <div className="smart-banking-step d-flex">
                  <div className="step-icon">
                    <i className="las la-check" />
                  </div>
                  <div className="step-content">
                    <p>No ATM fees. No minimum balance. No overdrafts.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="corporate-section ptb-80">
        <div className="container">
          <span className="sub-title text--base pb-3">Corporate Banking</span>
          <div className="corporate-area">
            <div className="corporate-item pt-30">
              <h3 className="title">
                Major products &amp; services under Corporate Banking are:
              </h3>
              <ul className="item pt-3">
                <li>Working Capital Financing</li>
                <li>Capital Machinery Financing</li>
                <li>Project Financing</li>
                <li>Lease Financing</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default About;
